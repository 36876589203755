/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useCallback } from "preact/hooks";
import { h, VNode, Fragment } from "preact";
import {
  useController,
  useFormContext,
  UseFormSetValue,
} from "react-hook-form";
import IdentifierPlaceholder from "../../atoms/vehicle-identifier-placeholder";
import Button from "../../atoms/button";
import useVehicleIdentifier from "../../../hooks/apis/use-vehicle-identifier";
import { Quote, VehicleIdentifierType } from "types";
import { Vehicle } from "../../../api/vehicle/types";
import { Text } from "preact-i18n";

const validations = {
  VIN: {
    pattern: /^[0-z]{17}$/,
    minLength: 17,
    maxLength: 17,
    validate: {
      complete: () => true,
      cpattern: () => true,
    },
  },
  licensePlate: {
    pattern: /^[A-z]{2}-?[0-9]{3}-?[A-z]{2}$/,
    minLength: 9,
    maxLength: 9,
    validate: {
      complete: () => true,
      cpattern: () => true,
    },
  },

  oldLicensePlate: {
    validate: {
      complete: (v) => !!v.match(/^[0-9]{2,4}\s.{1,3}\s?[0-9]{2}$/),
      cpattern: (v) => !!v.match(/^[0-9]{2,4}\s?[A-z]{1,3}\s?[0-9]{2}$/),
    },
    isValid: /^[0-9]{2,4}\s?[A-z]{1,3}\s?[0-9]{2}$/,
    pattern: undefined,
    maxLength: 11,
    minLength: undefined,
  },
};

const isIdentifierValid = (
  error: string,
  isDirty: boolean,
  vehicleIdentifier: string,
  vehicleIdentifierType: VehicleIdentifierType
): boolean => {
  const isOldLicensePlate = vehicleIdentifierType === "oldLicensePlate";
  const isValid = isOldLicensePlate
    ? !!vehicleIdentifier?.match(validations[vehicleIdentifierType].isValid)
    : !!vehicleIdentifier?.match(validations[vehicleIdentifierType].pattern);

  return !error && !!isDirty && isValid;
};

const filterOutSpecialCharacters = (e) => {
  if (!/[a-zA-Z0-9]/.test(e.key)) {
    e.preventDefault();
  }
};

const getErrorToDisplay = (
  error: string,
  isVIN: boolean,
  setValue: UseFormSetValue<Quote>
) => {
  const identifierName = isVIN ? "code VIN" : "plaque d'immatriculation";
  switch (error) {
    case "maxLength":
      return (
        <Text
          id="vehicle.licensePlateMaxLengthError"
          fields={{ identifierName }}
        />
      );
    case "pattern":
      return (
        <Text
          id="vehicle.licensePlatePatternError"
          fields={{ identifierName }}
        />
      );
    case "failed-api-validation":
      return (
        <>
          <Text id="vehicle.licensePlateAPIError" fields={{ identifierName }} />{" "}
          <Button
            label={<Text id="vehicle.model" />}
            isLink
            onClick={() => setValue("vehicle.tabIndex", 1)}
            className="font-bold"
          />
        </>
      );
    default:
      return null;
  }
};

const VehicleIdentifier = (): VNode => {
  const {
    control,
    setValue,
    getValues,
    setFocus,
    setError,
    clearErrors,
    formState: { errors, dirtyFields },
  } = useFormContext<Quote>();

  const vehicleIdentifierType = getValues("vehicle.identifier.type");
  const isVIN = vehicleIdentifierType === "VIN";

  const {
    field: {
      value: vehicleIdentifier,
      onChange: setVehicleIdentifier,
      ref: vehicleIdentifierRef,
    },
  } = useController({
    control,
    name: "vehicle.identifier.identifier",
    rules: {
      ...validations[vehicleIdentifierType],
    },
  });

  const handleSuccessIdentifierValidation = useCallback(
    (vehicle: Vehicle) => {
      const currentVehicle = getValues("vehicle.identifier.vehicle");
      if (currentVehicle?.id === vehicle.id) return;
      setValue("vehicle.identifier.vehicle", vehicle);
      clearErrors("vehicle.identifier.identifier");
      setValue("vehicle.model", {
        make: undefined,
        model: undefined,
        version: undefined,
      });
      setValue("selectedInput", undefined);
    },
    [setValue, clearErrors, getValues]
  );

  const handleFailedIdentifierValidation = useCallback(() => {
    setValue("vehicle.identifier.vehicle", undefined);
    setError("vehicle.identifier.identifier", {
      type: "failed-api-validation",
    });
  }, [setError, setValue]);

  const isDirty = dirtyFields?.vehicle?.identifier?.identifier;
  const error = errors?.vehicle?.identifier?.identifier.type;
  const isValid = isIdentifierValid(
    error,
    isDirty,
    vehicleIdentifier,
    vehicleIdentifierType
  );
  const { isError: isAPIError, data: vehicle } = useVehicleIdentifier(
    vehicleIdentifier,
    vehicleIdentifierType,
    isValid
  );

  useEffect(() => {
    if (vehicle) handleSuccessIdentifierValidation(vehicle);
  }, [vehicle, handleSuccessIdentifierValidation]);

  useEffect(() => {
    if (isAPIError) handleFailedIdentifierValidation();
  }, [isAPIError, handleFailedIdentifierValidation]);

  useEffect(() => {
    setFocus("vehicle.identifier.identifier");
  }, [setFocus]);

  const setVehicleIdentifierType = useCallback(
    (type: VehicleIdentifierType) => {
      setValue("vehicle.identifier.type", type);
    },
    [setValue]
  );

  useEffect(() => {
    if (vehicleIdentifierType === "VIN") return;
    const isOldLicensePlate = !!vehicleIdentifier.match(/^[0-9].*/);
    if (isOldLicensePlate) {
      setVehicleIdentifierType("oldLicensePlate");
    } else {
      return setVehicleIdentifierType("licensePlate");
    }
  }, [vehicleIdentifier[0], vehicleIdentifierType, setVehicleIdentifierType]);

  const handleVehicleIdentifierUpdate = useCallback(
    (e): void => {
      e.preventDefault();
      let newValue = (e.target as HTMLInputElement).value.toUpperCase();
      if (newValue === "") {
        setValue("vehicle.identifier.vehicle", undefined);
        setValue("services", undefined);
      }
      const isCurrentLicenseplate = !!newValue.match(/^[A-Z].*/);
      const isOldLicenseplate = !!newValue.match(/^[0-9].*/);

      if (!isVIN && isCurrentLicenseplate) {
        const oldValue = getValues("vehicle.identifier.identifier");
        const isRemovingAChar = oldValue.length > newValue.length;

        const shouldAddAnHyphen = 
          newValue.match(/^[A-Z]{2}$/) || 
          newValue.match(/^[A-Z]{2}-[A-Z0-9]{3}$/);

        const shouldAddAnHyphenOldValue = 
          oldValue.match(/^[A-Z]{2}$/) ||
          oldValue.match(/^[A-Z]{2}-[A-Z0-9]{3}$/);

        if (!isRemovingAChar) {
          if (shouldAddAnHyphen) {
            newValue = `${newValue}-`;
          }

          if (shouldAddAnHyphenOldValue) {
            // e.data is the single character
            newValue = `${oldValue}-${e.data}`;
          }
        } else {
          const shouldAddAnHyphenAfterRemovingAChar = newValue.match(/.*-$/);
          if (shouldAddAnHyphenAfterRemovingAChar) {
            newValue = newValue.slice(0, -1);
          }
        }
      } else if (!isVIN && isOldLicenseplate) {
        const shouldAddAWhiteSpace =
          newValue.match(/^[0-9]{4}$/) ||
          newValue.match(/^[0-9]{2,4}\s[A-Z]{3}$/);
        const shouldAddAWhiteSpaceBeforeLastChar =
          newValue.match(/^[0-9]{2,4}[A-Z]{1}$/) ||
          newValue.match(/^[0-9]{2,4}\s[A-Z]{2,3}[0-9]{1}$/);
        if (shouldAddAWhiteSpace) {
          newValue = `${newValue} `;
        }
        if (shouldAddAWhiteSpaceBeforeLastChar) {
          newValue = `${newValue.slice(
            0,
            newValue.length - 1
          )} ${newValue.slice(newValue.length - 1)}`;
        }
      }
      setVehicleIdentifier(newValue);
    },
    [isVIN, setVehicleIdentifier, setValue]
  );
  const errorToDisplay = getErrorToDisplay(error, isVIN, setValue);
  return (
    <div className={`w-full flex flex-col items-center`}>
      <div
        className={`relative p-2 font-licensePlate ${
          isVIN
            ? "h-12 text-2xl w-[280px] lg:h-8 lg:text-xl lg:w-[240px] xl:h-12 xl:text-2xl xl:w-[280px]"
            : `h-14 text-4xl lg:text-[38px] leading-[49px] ${
                vehicleIdentifierType === "licensePlate"
                  ? "w-[250px]"
                  : "w-[280px] lg:text-[34px] lg:w-[250px] xl:w-[280px]"
              }`
        }`}
      >
        <input
          type="text"
          value={vehicleIdentifier}
          onChange={handleVehicleIdentifierUpdate}
          onKeyPress={filterOutSpecialCharacters}
          className={`text-black bg-transparent w-full z-10 absolute uppercase box-border focus:outline-none tracking-[1.6px]  ${
            error && error !== "minLength" && error !== "complete"
              ? "text-red"
              : ""
          }`}
          ref={vehicleIdentifierRef}
          maxLength={validations[vehicleIdentifierType].maxLength}
        />
        <IdentifierPlaceholder
          identifierType={vehicleIdentifierType}
          identifier={vehicleIdentifier}
        />
      </div>
      {errorToDisplay && (
        <p className="max-w-[280px] my-4 text-center">{errorToDisplay}</p>
      )}
    </div>
  );
};
export default VehicleIdentifier;